import { ContractInterface } from 'ethers';
import erc1155Abi from './abi/erc1155.json';
import erc721Abi from './abi/erc721.json';
import erc1155AbiV3 from './abi/v3erc1155.json';

export enum ItemContractType {
  erc1155 = 'erc1155',
  erc721 = 'erc721',
}

export interface ItemContractConfig {
  name: string;
  contractType: ItemContractType;
  contractAddress: string;
  contractInterface: ContractInterface;
}

export const itemContractConfigs: ItemContractConfig[] = [
  {
    name: 'Gala Items',
    contractType: ItemContractType.erc1155,
    contractAddress: process.env.galaItemContractAddress as string,
    contractInterface: erc1155Abi as ContractInterface,
  },
  {
    name: 'Vox Series 1',
    contractType: ItemContractType.erc721,
    contractAddress: process.env.voxContractAddress as string,
    contractInterface: erc721Abi as ContractInterface,
  },
  {
    name: 'The Walking Dead Vox',
    contractType: ItemContractType.erc721,
    contractAddress: process.env.twdVoxContractAddress as string,
    contractInterface: erc721Abi as ContractInterface,
  },
  {
    name: 'Genesis Curry Flow',
    contractType: ItemContractType.erc1155,
    contractAddress: process.env.curryContractAddress as string,
    contractInterface: erc1155Abi as ContractInterface,
  },
  {
    name: 'Mirandus Vox',
    contractType: ItemContractType.erc721,
    contractAddress: process.env.mirandusVoxContractAddress as string,
    contractInterface: erc721Abi as ContractInterface,
  },
  {
    name: 'Blue Realm',
    contractType: ItemContractType.erc721,
    contractAddress: process.env.blueRealmContractAddress as string,
    contractInterface: erc721Abi as ContractInterface,
  },
  {
    name: 'Spider Tanks Item',
    contractType: ItemContractType.erc1155,
    contractAddress: process.env.spiderTanksItemContractAddress as string,
    contractInterface: erc1155AbiV3 as ContractInterface,
  },
  {
    name: 'Town Star Item',
    contractType: ItemContractType.erc1155,
    contractAddress: process.env.townStarItemContractAddress as string,
    contractInterface: erc1155AbiV3 as ContractInterface,
  },
  {
    name: 'Grit',
    contractType: ItemContractType.erc721,
    contractAddress: process.env.gritItemContractAddress as string,
    contractInterface: erc721Abi as ContractInterface,
  },
  {
    name: 'Dragon Strike Item',
    contractType: ItemContractType.erc1155,
    contractAddress: process.env.dragonStrikeContractAddress as string,
    contractInterface: erc1155AbiV3 as ContractInterface,
  },
  {
    name: "Champion's Arena Item",
    contractType: ItemContractType.erc1155,
    contractAddress: process.env.championsArenaItemContractAddress as string,
    contractInterface: erc1155AbiV3 as ContractInterface,
  },
  {
    name: 'PokerGO Item',
    contractType: ItemContractType.erc1155,
    contractAddress: process.env.pokerGoItemContractAddress as string,
    contractInterface: erc1155AbiV3 as ContractInterface,
  },
  {
    name: 'Legacy Item',
    contractType: ItemContractType.erc1155,
    contractAddress: process.env.legacyItemContractAddress as string,
    contractInterface: erc1155AbiV3 as ContractInterface,
  },
  {
    name: 'Echos of Empire Item',
    contractType: ItemContractType.erc1155,
    contractAddress: process.env.echoesOfEmpireItemContractAddress as string,
    contractInterface: erc1155AbiV3 as ContractInterface,
  },
  {
    name: 'Eternal Paradox Item',
    contractType: ItemContractType.erc1155,
    contractAddress: process.env.eternalParadoxItemContractAddress as string,
    contractInterface: erc1155AbiV3 as ContractInterface,
  },
  {
    name: 'MeowPets Item',
    contractType: ItemContractType.erc1155,
    contractAddress: process.env.meowPetsItemContractAddress as string,
    contractInterface: erc1155AbiV3 as ContractInterface,
  },
  {
    name: 'Mirandus Item',
    contractType: ItemContractType.erc1155,
    contractAddress: process.env.mirandusItemContractAddress as string,
    contractInterface: erc1155AbiV3 as ContractInterface,
  },
  {
    name: 'Collectibles Item',
    contractType: ItemContractType.erc1155,
    contractAddress: process.env.collectiblesItemContractAddress as string,
    contractInterface: erc1155AbiV3 as ContractInterface,
  },
  {
    name: 'Battlestar Galactica Item',
    contractType: ItemContractType.erc1155,
    contractAddress: process.env
      .battlestarGalacticaItemContractAddress as string,
    contractInterface: erc1155AbiV3 as ContractInterface,
  },
  {
    name: 'Last Expedition Item',
    contractType: ItemContractType.erc1155,
    contractAddress: process.env.lastExpeditionItemContractAddress as string,
    contractInterface: erc1155AbiV3 as ContractInterface,
  },
  {
    name: 'TWDE Item',
    contractType: ItemContractType.erc1155,
    contractAddress: process.env.twdeItemContractAddress as string,
    contractInterface: erc1155AbiV3 as ContractInterface,
  },
  {
    name: 'Superior Item',
    contractType: ItemContractType.erc1155,
    contractAddress: process.env.superiorItemContractAddress as string,
    contractInterface: erc1155AbiV3 as ContractInterface,
  },
  {
    name: 'Fortitude Item',
    contractType: ItemContractType.erc1155,
    contractAddress: process.env.fortitudeItemContractAddress as string,
    contractInterface: erc1155AbiV3 as ContractInterface,
  },
  {
    name: 'Legends Reborn Item',
    contractType: ItemContractType.erc1155,
    contractAddress: process.env.legendsRebornItemContractAddress as string,
    contractInterface: erc1155AbiV3 as ContractInterface,
  },
  {
    name: 'Node Item',
    contractType: ItemContractType.erc1155,
    contractAddress: process.env.nodeItemContractAddress as string,
    contractInterface: erc1155AbiV3 as ContractInterface,
  },
  {
    name: 'GalaTag Item',
    contractType: ItemContractType.erc1155,
    contractAddress: process.env.galaTagItemContractAddress as string,
    contractInterface: erc1155AbiV3 as ContractInterface,
  },
  {
    name: 'VEXI Item',
    contractType: ItemContractType.erc1155,
    contractAddress: process.env.vexiItemContractAddress as string,
    contractInterface: erc1155AbiV3 as ContractInterface,
  },
];
